<template>
  <q-form ref="editForm">
    <!-- 조사계획 -->
    <c-card title="LBL0002377" class="cardClassDetailForm">
      <!-- 버튼 영역 -->
      <template slot="card-button">
        <q-btn-group outline>
          <!-- 저장 -->
          <c-btn
            v-if="!disabled&& !isInvestY"
            :url="updateUrl"
            :isSubmit="isSave"
            :param="tabParam"
            mappingType="PUT"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveAccident"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-9 col-sm-9 col-md-3 col-lg-3 col-xl-3">
          <!-- 조사대상 여부 -->
          <c-radio
            :required="true" 
            :editable="editable"
            :disabled="disabled || tabParam.accidentStatusCd === 'ISPC000004'"
            :comboItems="checkTargetItems"
            :range="true"
            label="LBL0002379"
            name="investigationFlag"
            v-model="tabParam.investigationFlag"
            @datachange="datachange"
            >
          </c-radio>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-3 col-lg-3 col-xl-3">
          <!-- 기간 -->
          <c-datepicker
            :editable="editable"
            :disabled="disabled || isInvestY"
            :range="true"
            label="LBLPERIOD"
            name="investigationDate"
            v-model="investigationDate"
            @datachange="datechange">
          </c-datepicker>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-6 col-lg-6 col-xl-6">
          <!-- 계획 -->
          <c-textarea
            :editable="editable"
            :disabled="disabled || isInvestY"
            label="LBLPLAN"
            :rows="4"
            name="investigationPlan"
            v-model="tabParam.investigationPlan">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <!-- 사고조사팀 -->
      <c-table
        ref="table"
        title="LBL0002380"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="tabParam.accidentInvestigationTeamModelList"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :editable="editable && !disabled && !isInvestY"
        selection="multiple"
        rowKey="researcherId"
      >
        <template slot="table-button">
          <q-btn-group outline>
            <!-- 추가 -->
            <c-btn v-if="editable && !disabled && !isInvestY" :showLoading="false" label="LBLADD" icon="add" @btnClicked="add" />
            <!-- 제외 -->
            <c-btn v-if="editable && !disabled && !isInvestY && tabParam.accidentInvestigationTeamModelList.length > 0" :showLoading="false"  label="LBLEXCEPT" icon="remove" @btnClicked="remove" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'process-accident-reception-plan',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
      }),
    },
    tabParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        investigationFlag: '',
        investigationStartDate: '',
        investigationEndDate: '',
        investigationPlan: '',
        reportDate: '',
        occurrenceCause: '',
        accidentDamageHistory: '',
        emergencyMeasures: '',
        materialLossHistory: '',
        materialLossAmount: '',
        enviromentLeakInformation: '',
        enviromentImpactHistory: '',
        accidentStatusCd: '',

        accidentInvestigationTeamModelList: [],
        deleteAccidentInvestigationTeamModelList: [],
        accidentVictimModelList: [],
        deleteAccidentVictimModelList: [],
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'researcherDeptName',
            field: 'researcherDeptName',
            // 부서
            label: 'LBLDEPT',
            style: 'width:300px',
            align: 'center',
            sortable: false
          },
          {
            name: 'researcherName',
            field: 'researcherName',
            // 이름
            label: 'LBLNAME',
            style: 'width:300px',
            align: 'center',
            sortable: false
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            // 생년월일
            label: 'LBL0002341',
            style: 'width:300px',
            align: 'center',
            sortable: false
          },
          {
            name: 'researcherPosition',
            field: 'researcherPosition',
            // 직급
            label: 'LBL0002342',
            style: 'width:300px',
            align: 'center',
            sortable: false
          },
          // {
          //   name: 'insideOutsideTypeCd',
          //   field: 'insideOutsideTypeCd',
          //   label: '내/외부',
          //   align: 'center',
          //   style: 'width:100px',
          //   type: 'select',
          //   comboItems: [
          //     { code: 'Y', codeName: '내부직원' },
          //     { code: 'N', codeName: '외부직원' },
          //   ],
          //   sortable: false,
          // },
          {
            name: 'researcherRole',
            field: 'researcherRole',
            // 역할
            label: 'LBLROLE',
            type: "text",
            style: 'width:400px',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
        height: '300px'
      },
      checkTargetItems: [
        { code: 'Y', codeName: '대상' },
        { code: 'N', codeName: '비대상' },
      ],
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      editable: true,
      searchUrl: '',
      isSave: false,
      getUrl: '',
      updateUrl: 'transactionConfig.sop.iim.accident.process.investigation.update.url',
      isInvestY: false,
      investigationDate: [],
    };
  },
  computed: {
    disabled() {
      return this.editable && Boolean(this.popupParam.stepCd) && (this.popupParam.stepCd === 'ISPC000005')
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.updateUrl = transactionConfig.sop.iim.accident.process.investigation.update.plan.url;
      // code setting

      if (this.tabParam.investigationStartDate, this.tabParam.investigationEndDate) {
        this.investigationDate = [this.tabParam.investigationStartDate, this.tabParam.investigationEndDate]
      }
      // list setting
    },
    add() {
      this.popupOptions.title = 'LBL0002346'; // 사고자 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tabParam.accidentInvestigationTeamModelList, {
            researcherId: _item.userId,
          });
          if (index === -1) {
            this.tabParam.accidentInvestigationTeamModelList.splice(0, 0, {
              iimAccidentId: this.popupParam.iimAccidentId,
              researcherNo: uid(),
              researcherDeptName: _item.deptName,
              researcherId: _item.userId,
              researcherName: _item.userName,
              birthDate: _item.birthDate,
              researcherRole: '',
              researcherPosition: _item.spotName,
              insideOutsideTypeCd: null,
              editFlag: 'C'
            })
          }
        })
      }
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.tabParam.accidentInvestigationTeamModelList = this.$_.reject(this.tabParam.accidentInvestigationTeamModelList, { researcherId: item.researcherId })
        })
        this.$_.forEach(selectData, item => {
          if (!this.tabParam.deleteAccidentInvestigationTeamModelList) {
              this.tabParam.deleteAccidentInvestigationTeamModelList = []
          }
          if (this.$_.findIndex(this.tabParam.deleteAccidentInvestigationTeamModelList, { researcherId: item.researcherId }) === -1
          && item.editFlag !== 'C') {
            this.tabParam.deleteAccidentInvestigationTeamModelList.push(item)
          }
          this.tabParam.accidentInvestigationTeamModelList = this.$_.reject(this.tabParam.accidentInvestigationTeamModelList, 
          { researcherId: item.researcherId })
        })
      }
    },
    saveAccident() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              if (this.investigationDate && this.investigationDate.length > 0) {
                this.tabParam.investigationStartDate = this.investigationDate[0];
                this.tabParam.investigationEndDate = this.investigationDate[1];
              }
              this.tabParam.regUserId = this.$store.getters.user.userId
              this.tabParam.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.iimAccidentId = result.data;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail')
      this.$emit('currentStep')
    },
    datachange() {
      if (this.tabParam.investigationFlag === 'N' && !this.isInvestY) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM', // 확인
          message: 'MSG0000706', // 조사정보가 초기화 됩니다.\n변경하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.tabParam.deleteAccidentInvestigationTeamModelList = this.$_.clone(this.tabParam.accidentInvestigationTeamModelList); // 조사인원 데이터
            this.tabParam.accidentInvestigationTeamModelList = []; // 조사인원 데이터
            this.tabParam.investigationStartDate = '';
            this.tabParam.investigationEndDate = '';
            this.tabParam.investigationPlan = '';
            this.tabParam.reportDate = '';
            this.tabParam.occurrenceCause = '';
            this.tabParam.emergencyMeasures = '';
            this.tabParam.accidentDamageHistory = '';
            this.tabParam.materialLossHistory = '';
            this.tabParam.materialLossAmount = '';
            this.tabParam.enviromentLeakInformation = '';
            this.tabParam.enviromentImpactHistory = '';

            this.investigationDate = [];
            this.$http.url = transactionConfig.sop.iim.accident.process.investigation.update.url;
            this.$http.type = 'PUT';
            this.$http.param = this.tabParam
            this.$http.request(() => {
              this.$http.url = transactionConfig.sop.iim.accident.process.investigation.update.status.url
              this.$http.type = 'PUT';
              this.$http.param = {
                investigationFlag: this.tabParam.investigationFlag,
                iimAccidentId: this.tabParam.iimAccidentId,
                accidentStatusCd: 'ISPC000002',
              }
              this.$http.request(() => {
                
                this.isInvestY = false;
                this.isInvestY = true;
                this.$emit('isInvestY', this.isInvestY)
              },);
            },);
            
          },
          // 취소 callback 함수
          cancelCallback: () => {
            this.tabParam.investigationFlag = 'Y';
            this.isInvestY = false;
            this.$emit('isInvestY', this.isInvestY)
          },
        });
        // 상태변경
      } else if(this.tabParam.investigationFlag === 'Y') {
        this.$http.url = transactionConfig.sop.iim.accident.process.investigation.update.status.url
        this.$http.type = 'PUT';
        this.$http.param = {
          investigationFlag: this.tabParam.investigationFlag,
          iimAccidentId: this.tabParam.iimAccidentId,
          accidentStatusCd: 'ISPC000003',
        }
        this.$http.request(() => {
        },);
        this.isInvestY = false;
        this.$emit('isInvestY', this.isInvestY)
      }
    },
    datechange() {
      if (this.investigationDate && this.investigationDate.length > 0) {
        this.$set(this.tabParam, 'investigationStartDate', this.investigationDate[0])
        this.$set(this.tabParam, 'investigationEndDate', this.investigationDate[1])
      } else {
        this.$set(this.tabParam, 'investigationStartDate', '')
        this.$set(this.tabParam, 'investigationEndDate', '')
      }
    }
  }
};
</script>
